import { v4 as uuidv4 } from 'uuid';
import React, { useReducer } from 'react';
import { IAdLoop as Ad } from './requests';
// import { AdType } from '../../types';

// type SetCount = {
//   type: AdType;
//   count: number;
// };

export enum StrapiComponentType {
  News = 'news',
  Weather = 'weather',
}

export type Actions =
    { type: 'SET_LOADING' } |
    { type: 'SET_ERROR' } |
    { type: 'TOGGLE_LOADING', payload: boolean } |
    { type: 'SET_AD', payload: Ad } |
    // { type: 'SET_COUNT', payload: SetCount } |
    // { type: 'SET_INITIAL_COUNTERS', payload: AdType[] } |
    { type: 'SET_COMPONENT', payload: StrapiComponentType };
    // { type: 'SET_QUEUE_CONFIG', payload: QueueConfig };

export const adsInitialState = {
  uuid: uuidv4(),
  loading: false,
  error: false,
  ad: null as unknown as Ad,
  component: null as unknown as StrapiComponentType,
  isActive: true,
  // config: {
  //   weights: {
  //     provider: 16,
  //     direct: 1,
  //     fallback: 1,
  //     custom: 1,
  //     component: 1,
  //   },
  // } as Record<string, any>,
  // counts: {
  //   // [AdType.Hivestack]: 0,
  //   // [AdType.Broadsign]: 0,
  // } as Record<string, number>,
};

export const adsReducer = (state = adsInitialState, action: Actions): typeof adsInitialState => {
  switch (action.type) {
    case 'SET_LOADING': {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case 'TOGGLE_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'SET_AD': {
      return {
        ...state,
        component: null as unknown as StrapiComponentType,
        ad: action.payload,
        uuid: uuidv4(),
      };
    }
    // case 'SET_COUNT': {
    //   return {
    //     ...state,
    //     counts: {
    //       ...state.counts,
    //       [action.payload.type]: action.payload.count,
    //     },
    //   };
    // }
    // case 'SET_INITIAL_COUNTERS': {
    //   const initialCounts = {} as Record<string, number>;
    //   action.payload.forEach((type) => { initialCounts[type] = 0; });
    //   return {
    //     ...state,
    //     counts: initialCounts,
    //   };
    // }
    case 'SET_COMPONENT': {
      return {
        ...state,
        component: action.payload,
        ad: null as unknown as Ad,
        uuid: uuidv4(),
      };
    }
    // case 'SET_QUEUE_CONFIG': {
    //   return {
    //     ...state,
    //     config: action.payload,
    //   };
    // }
    default: {
      return state;
    }
  }
};

type UseAdLoopReducerReturnType = [typeof adsInitialState, React.Dispatch<Actions>];
export const useAdLoopReducer = ():UseAdLoopReducerReturnType => useReducer(adsReducer, adsInitialState);
